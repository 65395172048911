'use strict';

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.date.to-string");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.function.bind");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

var hasSymbols = require('has-symbols')();

var hasToStringTag = hasSymbols && typeof Symbol.toStringTag === 'symbol';
var regexExec;
var isRegexMarker;
var badStringifier;

if (hasToStringTag) {
  regexExec = Function.call.bind(RegExp.prototype.exec);
  isRegexMarker = {};

  var throwRegexMarker = function throwRegexMarker() {
    throw isRegexMarker;
  };

  badStringifier = {
    toString: throwRegexMarker,
    valueOf: throwRegexMarker
  };

  if (typeof Symbol.toPrimitive === 'symbol') {
    badStringifier[Symbol.toPrimitive] = throwRegexMarker;
  }
}

var toStr = Object.prototype.toString;
var regexClass = '[object RegExp]';
module.exports = hasToStringTag // eslint-disable-next-line consistent-return
? function isRegex(value) {
  if (!value || typeof value !== 'object') {
    return false;
  }

  try {
    regexExec(value, badStringifier);
  } catch (e) {
    return e === isRegexMarker;
  }
} : function isRegex(value) {
  // In older browsers, typeof regex incorrectly returns 'function'
  if (!value || typeof value !== 'object' && typeof value !== 'function') {
    return false;
  }

  return toStr.call(value) === regexClass;
};